import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Styles from './Text.styles';

const TextComponent = styled.div`${Styles.text}`; // prettier-ignore

/**
 * Text Component
 * @param {Object} props - component props
 * @param {boolean} [params.bold] - if true, use bold
 * @param {Array|element|function|Node} params.children - component childs
 * @param {string} [props.className] - modifier class
 * @param {boolean} [params.medium] - if true, use medium
 * @param {boolean} [params.oneLine] - if true, render text in one line
 * @param {string} [params.tag = 'div'] - specifies the tag type
 * @param {boolean} [unselectable] - if true, text cannot be selected
 * @returns {JSX}
 */
const Text = ({
  bold, children, className, medium, oneLine, tag, unSelectable
}) => {
  if (!children) return null;

  return (
    <TextComponent
      as={tag}
      bold={bold}
      className={className}
      medium={medium}
      oneLine={oneLine}
      unSelectable={unSelectable}
    >
      {children}
    </TextComponent>
  );
};

Text.propTypes = {
  bold: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  medium: PropTypes.bool,
  oneLine: PropTypes.bool,
  tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'div',
    'span',
    'p',
    'em',
  ]),
  unSelectable: PropTypes.bool,
};

export default Text;

import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getKey } from '../helpers';
import Post from '../components/compound/Post';

/**
 * Page Component
 * @param {Object} props - component props
 * @returns {JSX}
 */
const Page = props => {
  const posts = getKey(props, 'data.allHubspotPost.edges', []);
  const { description, title } = getKey(posts[0], 'node.meta', {});
  const isBitAndBytes = getKey(props, 'pageContext.isBitAndBytes');
  const isTechCast = getKey(props, 'pageContext.isTechCast');
  const isDeathMatch = getKey(props, 'pageContext.isDeathMatch');
  const callToAction = getKey(props, 'data.callToAction.edges[0].node.callToAction');

  return (
    <>
      {title && (
        <Helmet
          title={`Alestra - ${title}`}
          meta={[
            { content: description, name: 'description' },
            { content: `${title}, alestra`, name: 'keywords' },
          ]}
        >
          <script async src="https://platform.linkedin.com/in.js" type="text/javascript">lang: es_ES</script>
          {isBitAndBytes && <script src="https://apis.google.com/js/platform.js" />}
          <html lang="es" />
        </Helmet>
      )}
      <Post
        {...posts[0].node}
        key={title}
        isBitAndBytes={isBitAndBytes}
        isTechCast={isTechCast}
        isDeathMatch={isDeathMatch}
        callToAction={callToAction}
      />
    </>
  );
};

export default Page;

export const blogListQuery = graphql`
  query postQuery($postId: String!) {
    allHubspotPost: allHubspotPost(
      filter: {id: {eq: $postId}}
    ) {
      edges {
        node {
          slug
          title
          body
          publishedDay : published_format(formatString: "D")
          publishedMonth : published_format(formatString: "MMMM", locale: "es")
          publishedYear : published_format(formatString: "YYYY")
          publishedTime : published_format(formatString: "h:mm A")
          topics {
            name
            topicId: topic_id
          }
          meta {
            description
            title
          }
        }
      }
    }
    callToAction: allStrapiPages(
      filter: {
        slug: {
          eq: "/blog"
        }
      }
    ) {
      edges {
        node {
          callToAction {
            url
            image {
              url
            }
          }
        }
      }
    }
  }
`;

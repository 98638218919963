import { css } from 'styled-components';

import { media, rem } from '../../../helpers/mixins';
import {
  CERULEAN,
  MID_GRAY,
  WOODSMOKE_RGB,
  WHITE,
} from '../../../constants/colors';

export default {
  blogHeaderSticky: css`
    background-color: ${`rgba(${WOODSMOKE_RGB}, 1)`};
    height: 50px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 4;

    ${media.md`
      background-color: ${`rgba(${WOODSMOKE_RGB}, 0.95)`};
    `}
  `,
  content: css`
    margin-bottom: 32px;
    margin-top: 32px;

    img {
      height: auto;
      margin-bottom: 8px !important;
      margin-left: -24px;
      width: calc(100% + 48px) !important;

      ${media.md`
        margin: 0;
        width: 100% !important;
      `}
    }

    a {
      color: ${CERULEAN};
    }
  `,
  ctaImage: css`
    height: auto;
    padding: 0 8px;
    width: 100%;
  `,
  ctaWrapper: css`
    height: auto;
    margin-left: -24px;
    width: calc(100% + 48px);
  `,
  dateInfo: css`
    color: ${MID_GRAY};
    font-size: ${rem(12)};
    margin-top: 16px;

    ${media.md`
      font-size: ${rem(13)};
      margin-top: 20px;
    `}
  `,
  shareBar: css`
    display: flex;
    margin-top: 4px;
    width: 100%;

    .twitter-share-button {
      min-width: 71px;
    }

    & > * {
      margin-right: 4px !important;
    }
  `,
  subscribeWrapper: css`
    margin-top: 0;

    ${media.md`
      margin-top: 40px;
    `}
  `,
  tagged: css`
    color: rgba(100, 100, 100, 1);
    font-size: ${rem(12)};
    font-weight: 700;
    margin-bottom: 8px;
    width: 100%;
  `,
  title: css`
    color: ${CERULEAN};
    display: block;
    font-size: ${rem(23)};
    font-weight: 700;
    line-height: ${rem(28)};
    margin-top: 24px;

    ${media.md`
      font-size: ${rem(32)};
      line-height: ${rem(38)};
      margin-top: 32px;
    `}
  `,
  topics: css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 54px;
    margin-top: 16px;
  `,
  topicsLabels: ({ color }) => css`
    align-items: center;
    ${'' /* background-color: ${`rgba(${224 - color}, ${118 + color}, ${Math.max(220 + color, 255)}, 1)`}; */}
    background-color: ${CERULEAN};
    border-radius: 20px;
    color: ${WHITE};
    display: flex;
    font-size: ${rem(12)};
    height: 22px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 0 12px;
    text-transform: uppercase;

    &&& {
      color: ${WHITE};
    }
  `,
  wrapper: css`
    min-height: 1000px;
  `,
  youtube: css`
    color: ${CERULEAN};
    font-size: ${rem(18)};
    font-weight: 700;
    line-height: ${rem(22)};
    margin-bottom: 32px;
    margin-top: 4px;
  `,
};

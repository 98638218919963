import { css } from 'styled-components';

export default {
  text: ({
    bold,
    medium,
    oneLine,
    unselectable,
  }) => css`
    display: block;
    white-space: pre-wrap;
    word-wrap: break-word;

    ${bold && css`
      font-weight: 700;
    `}

    ${medium && css`
      font-weight: 500;
    `}

    ${unselectable && css`
      user-select: none;
    `}

    ${oneLine && css`
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
  `,
};

/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { createGlobalStyle } from 'styled-components';
import slugify from '@sindresorhus/slugify';
import { useDispatch } from 'react-redux';

import {
  capFirstLetter, isValidArray
} from '../../../helpers';
import { scrollToTop } from '../../../store/actions';
import Text from '../../base/Text';
import Link from '../../base/Link';
import Styles from './Post.styles';

const BlogStyle = createGlobalStyle`
  body {
    background-color: white !important;
  }

  .Header_Wrapper {
    &:after {
      display: none !important;
    }
  }
`;

const BlogHeaderSticky = styled.div`${Styles.blogHeaderSticky}`; // prettier-ignore
const Content = styled.div`${Styles.content}`; // prettier-ignore
const DateInfo = styled.div`${Styles.dateInfo}`; // prettier-ignore
const ShareBar = styled.div`${Styles.shareBar}`; // prettier-ignore
const SubscribeWrapper = styled.div`${Styles.subscribeWrapper}`; // prettier-ignore
const Tagged = styled.div`${Styles.tagged}`; // prettier-ignore
const Title = styled(Text)`${Styles.title}`; // prettier-ignore
const Topics = styled.div`${Styles.topics}`; // prettier-ignore
const TopicsLabels = styled(Link)`${Styles.topicsLabels}`; // prettier-ignore
const Wrapper = styled.div`${Styles.wrapper}`; // prettier-ignore
const YouTube = styled.div`${Styles.youtube}`; // prettier-ignore

/**
 * Post component
 * @param {string} body - post content
 * @param {string} title - post title
 * @returns {JSX}
 */
const Post = ({
  body,
  isBitAndBytes,
  publishedDay,
  publishedMonth,
  publishedTime,
  publishedYear,
  slug,
  title,
  topics,
}) => {
  const dispatch = useDispatch();
  const gotoTop = useCallback(() => {
    dispatch(scrollToTop());
  }, [dispatch]);
  const shareUrl = isBitAndBytes
    ? `https://www.alestradev.com/informacion/bits-and-bytes/episodio/${slug}`
    : `https://www.alestradev.com/blog/${slug}`;
  const topicLabels = !isBitAndBytes && isValidArray(topics) && topics.map((topic, idx) => {
    const { name, topicId } = topic;
    const categoryUri = `/blog/etiqueta/${topicId}/${slugify(name)}`;
    const color = idx * 7;

    return (
      <TopicsLabels color={color} key={`topic${name}`} href={categoryUri} onClick={gotoTop}>
        {name}
      </TopicsLabels>
    );
  });

  return (
    <>
      <BlogStyle key={`globalPost${title}`} />
      <Wrapper>
        <BlogHeaderSticky />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <Title>{title}</Title>
              <DateInfo>
                {`Publicado el ${publishedDay} de ${capFirstLetter(publishedMonth)} del ${publishedYear} a las ${publishedTime.toLowerCase()}`}
              </DateInfo>
              <ShareBar>
                <a
                  className="twitter-share-button"
                  href="https://twitter.com/intent/tweet"
                />
                <script type="IN/Share" data-url={shareUrl} data-showzero="true" data-counter="right" />
                <iframe
                  src={`https://www.facebook.com/plugins/like.php?href=${shareUrl}&width=159&layout=button&action=like&size=small&share=true&height=65&appId=1311304969061345`}
                  width="159"
                  height="20"
                  style={{ border: 'none', overflow: 'hidden' }}
                  scrolling="no"
                  frameBorder="0"
                  allow="encrypted-media"
                />
              </ShareBar>
              <Content dangerouslySetInnerHTML={{ __html: body }} />
              {!isBitAndBytes && (
                <Topics>
                  <Tagged>Etiquetado en:</Tagged>
                  {topicLabels}
                </Topics>
              )}
            </div>
            <SubscribeWrapper className="col-12 col-md-4">
              {isBitAndBytes && (
                <YouTube>
                  <p>Suscríbete a nuestro canal de YouTube</p>
                  <div className="g-ytsubscribe" data-channel="AlestraMX" data-layout="default" data-count="default" />
                </YouTube>
              )}
            </SubscribeWrapper>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

Post.propTypes = {
  body: PropTypes.string,
  isBitAndBytes: PropTypes.bool,
  publishedDay: PropTypes.string,
  publishedMonth: PropTypes.string,
  publishedTime: PropTypes.string,
  publishedYear: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  topics: PropTypes.array,
};

export default Post;
